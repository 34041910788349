<script setup lang="ts">
import type { AppInput } from "#components";

const emit = defineEmits<{ success: [] }>();

const { resetPassword, confirmationToken } = useAuth();

const formModel = reactive({
  password: "",
  repeatPassword: "",
});

const resetModel = () => {
  formModel.password = "";
  formModel.repeatPassword = "";
};

const errors = ref<Record<string, string>>({});

const onSubmit = async () => {
  errors.value = {};

  if (!formModel.password) {
    errors.value.password = "Podaj hasło";
    return;
  }

  if (!confirmationToken.value) {
    errors.value.password = "Brak tokenu";
    return;
  }

  if (formModel.password !== formModel.repeatPassword) {
    errors.value.repeatPassword = "Hasła nie są takie same";
    return;
  }

  return await resetPassword({
    password: formModel.password,
    token: confirmationToken.value,
  })
    .then(() => {
      emit("success");
      resetModel();
      confirmationToken.value = undefined;
    })
    .catch((response) => {
      const hasCode = (code: string) => {
        return !!response?.data?.errors?.find(
          (error: any) => error.extensions.code === code
        );
      };

      nextTick(() => {
        if (hasCode("INVALID_CREDENTIALS")) {
          errors.value.password = "Nieprawidowy token";
          errors.value.repeatPassword = "";
        } else if (hasCode("FORBIDDEN")) {
          errors.value.password =
            "Token wygasł, został już użyty lub jest nieprawidłowy. Ponów próbę resetu hasła";
          errors.value.repeatPassword = "";
        } else if (hasCode("FAILED_VALIDATION")) {
          errors.value.password =
            "Hasło musi mieć co najmniej 8 znaków, duże i małe litery oraz cyfry i znaki specjalne";
          errors.value.repeatPassword = "";
        } else {
          errors.value.password = "Wystąpił błąd";
          errors.value.repeatPassword = "";
        }
      });
    });
};
</script>

<template>
  <form
    @submit.prevent="onSubmit"
    action="/api/reset-password"
    method="post"
    class="app-auth-reset-form flex flex-col items-stretch"
  >
    <div class="app-auth-reset-form__wrapper grow flex flex-col items-stretch">
      <div
        class="app-auth-reset-form__fields grow flex flex-col items-stretch justify-center gap-8"
      >
        <div class="app-auth-reset-form__field">
          <AppInput
            label="Nowe hasło"
            type="password"
            v-model="formModel.password"
            autocomplete="new-password"
            :error="errors.password !== undefined"
            :hint="errors.password"
            absolute-hint
            required
          />
        </div>

        <div class="app-auth-reset-form__field">
          <AppInput
            label="Nowe hasło"
            type="password"
            v-model="formModel.repeatPassword"
            autocomplete="new-password"
            :error="errors.repeatPassword !== undefined"
            :hint="errors.repeatPassword"
            absolute-hint
            required
          />
        </div>
      </div>

      <div
        class="app-auth-reset-form__actions mt-6 flex flex-col items-stretch gap-6"
      >
        <AppBtn type="submit" variant="solid" block> Zapisz </AppBtn>
      </div>
    </div>
  </form>
</template>
