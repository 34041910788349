<script setup lang="ts">
import { AuthView } from "#imports";
import { withBase } from "ufo";

const config = useRuntimeConfig();

const emit = defineEmits<{ success: [] }>();

const { requestPasswordReset, openAuthDialog } = useAuth();

const formModel = reactive({
  email: "",
});

const resetModel = () => {
  formModel.email = "";
};

const errors = ref<Record<string, string>>({});

const onSubmit = async () => {
  errors.value = {};

  if (!formModel.email) {
    errors.value.email = "Podaj email";
    return;
  }

  return await requestPasswordReset({
    email: formModel.email,
    reset_url: withBase("/verify-reset", config.public.baseUrl),
  })
    .then(() => {
      emit("success");
      resetModel();
    })
    .catch((response) => {
      const hasCode = (code: string) => {
        return !!response?.data?.errors?.find(
          (error: any) => error.extensions.code === code
        );
      };

      nextTick(() => {
        if (hasCode("INVALID_CREDENTIALS")) {
          errors.value.email = "Nieprawidłowy email";
        } else {
          errors.value.email = "Wystąpił błąd";
        }
      });
    });
};
</script>

<template>
  <form
    @submit.prevent="onSubmit"
    action="/api/request-password-reset"
    method="post"
    class="app-auth-recover-form flex flex-col items-stretch"
  >
    <div
      class="app-auth-recover-form__wrapper grow flex flex-col items-stretch"
    >
      <div
        class="app-auth-recover-form__fields grow flex flex-col items-stretch justify-center gap-8"
      >
        <div class="app-auth-recover-form__field">
          <AppInput
            label="Email"
            type="email"
            v-model="formModel.email"
            autocomplete="email"
            :error="errors.email !== undefined"
            :hint="errors.email"
            absolute-hint
            required
          />
        </div>
      </div>

      <div
        class="app-auth-recover-form__actions mt-6 flex flex-col items-stretch gap-6"
      >
        <AppBtn type="submit" variant="solid" block> Wyślij link </AppBtn>

        <p
          class="text-center flex items-center justify-center flex-wrap gap-1 text-xs leading-[0.875rem]"
        >
          <span>Posiadasz konto?</span>
          <button
            class="font-bold underline hover:no-underline"
            @click="openAuthDialog(AuthView.Login)"
          >
            <span>Wróć do logowania</span>
          </button>
        </p>
      </div>
    </div>
  </form>
</template>
