<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.399 10.2271C20.399 9.51801 20.3347 8.83619 20.2154 8.18164H10.702V12.0498H16.1382C15.9041 13.2998 15.1924 14.3589 14.1226 15.068V17.5771H17.3871C19.2971 15.8362 20.399 13.2726 20.399 10.2271Z"
      fill="#4285F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7016 20.0004C13.4289 20.0004 15.7154 19.105 17.3867 17.5777L14.1222 15.0686C13.2177 15.6686 12.0607 16.0231 10.7016 16.0231C8.07077 16.0231 5.84396 14.264 5.04965 11.9004H1.67499V14.4913C3.33707 17.7595 6.75305 20.0004 10.7016 20.0004Z"
      fill="#34A853"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.05006 11.8997C4.84804 11.2997 4.73325 10.6588 4.73325 9.99971C4.73325 9.34062 4.84804 8.6997 5.05006 8.0997V5.50879H1.6754C0.99128 6.85879 0.601013 8.38607 0.601013 9.99971C0.601013 11.6133 0.99128 13.1406 1.6754 14.4906L5.05006 11.8997Z"
      fill="#FBBC05"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7016 3.97728C12.1846 3.97728 13.5161 4.48183 14.563 5.47274L17.4601 2.60455C15.7108 0.990911 13.4243 0 10.7016 0C6.75305 0 3.33707 2.24091 1.67499 5.5091L5.04965 8.10002C5.84396 5.73638 8.07077 3.97728 10.7016 3.97728Z"
      fill="#EA4335"
    />
  </svg>
</template>
