<script setup lang="ts">
const { loginWithGoogle, loginWithFacebook } = useAuth();

const emit = defineEmits<{
  setTitle: [title: string];
  setSuccess: [value: boolean];
}>();

const title = ref("Utwórz za pomocą:");
watch(title, (title) => emit("setTitle", title), { immediate: true });

const success = ref(false);
watch(success, (success) => emit("setSuccess", success), { immediate: true });

watch(
  success,
  (success) => {
    if (success) title.value = "Link  weryfikacyjny został wysłany";
    else title.value = "Utwórz za pomocą:";
  },
  { immediate: true }
);
</script>

<template>
  <div class="app-auth-register h-full" :class="{ 'pt-5': !success }">
    <div
      class="app-auth-register__wrapper h-full flex flex-col items-stretch gap-4"
    >
      <p v-if="success">
        Pamiętaj, że jest on ważny 24 godziny. Sprawdź skrzynkę pocztową.
      </p>

      <template v-else>
        <div
          class="app-auth-register__social flex flex-col items-stretch gap-6"
        >
          <AppBtn block variant="outline" @click="loginWithGoogle">
            <LogoGoogle class="w-6 h-6 mr-2" />
            <span>Konta Google</span>
          </AppBtn>

          <AppBtn block variant="outline" @click="loginWithFacebook">
            <LogoFacebook class="w-6 h-6 mr-2" />
            <span>Konta Facebook</span>
          </AppBtn>
        </div>

        <div
          class="app-auth-register__prompt text-center grow flex flex-col items-stretch justify-center"
        >
          <p class="text-center">lub za pomocą email</p>
        </div>
      </template>

      <div class="app-auth-register__form grow flex flex-col items-stretch">
        <AppAuthRegisterSuccess
          v-if="success"
          @close="success = false"
          class="grow"
        />
        <AppAuthRegisterForm v-else class="grow" @success="success = true" />
      </div>
    </div>
  </div>
</template>
