<script setup lang="ts">
const emit = defineEmits<{
  setTitle: [title: string];
  setSuccess: [value: boolean];
}>();

const title = ref("Ustaw i zapamiętaj");
watch(title, (title) => emit("setTitle", title), { immediate: true });

const success = ref(false);
watch(success, (success) => emit("setSuccess", success), { immediate: true });

watch(
  success,
  (success) => {
    if (success) title.value = "Gratulacje!";
    else title.value = "Ustaw i zapamiętaj";
  },
  { immediate: true }
);
</script>

<template>
  <div class="app-auth-reset h-full">
    <div
      class="app-auth-reset__wrapper h-full flex flex-col items-stretch gap-6"
    >
      <p v-if="success">
        Hasło zostało zmienione. Teraz możesz bezpiecznie zalogować się do
        naszego portalu.
      </p>
      <p v-else>
        Bezpieczne hasło powinno zawierać minimum 8 znaków w tym minimum jedną
        cyfrę i znak specjalny.
      </p>

      <div class="app-auth-reset__form grow flex flex-col items-stretch">
        <AppAuthResetSuccess
          v-if="success"
          class="grow"
          @close="success = false"
        />
        <AppAuthResetForm v-else class="grow" @success="success = true" />
      </div>
    </div>
  </div>
</template>
