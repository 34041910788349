<script setup lang="ts">
import type { AppInput } from "#components";
import { AuthView } from "#imports";

const { loginWithEmail, isAuthDialogOpen, openAuthDialog } = useAuth();

const formModel = reactive({
  email: "",
  password: "",
  otp: undefined as string | undefined,
});

const showPassword = ref(false);

const resetModel = () => {
  formModel.email = "";
  formModel.password = "";
  formModel.otp = undefined;
};

const otp = ref<InstanceType<typeof AppInput>>();

const requiresOtp = ref(false);

const enableOtp = () => {
  requiresOtp.value = true;
  formModel.otp = "";
};

const disableOtp = () => {
  requiresOtp.value = false;
  formModel.otp = undefined;
};

watch(
  () => [formModel.email, formModel.password],
  () => disableOtp(),
  { immediate: true }
);

const errors = ref<Record<string, string>>({});

const onSubmit = async () => {
  errors.value = {};

  return await loginWithEmail(
    formModel.email,
    formModel.password,
    formModel.otp
  )
    .then(() => {
      resetModel();
      isAuthDialogOpen.value = false;
    })
    .catch((response) => {
      const hasCode = (code: string) => {
        return !!response?.data?.errors?.find(
          (error: any) => error.extensions.code === code
        );
      };

      nextTick(() => {
        if (hasCode("INVALID_OTP")) {
          enableOtp();
          errors.value.otp =
            "Podaj jednorazowy kod z aplikacji uwierzytelniającej";
          nextTick(() => {
            otp.value?.focus();
          });
        } else if (hasCode("INVALID_CREDENTIALS")) {
          errors.value.email = "Nieprawidłowy email lub hasło";
          errors.value.password = "";
          errors.value.otp = "";
        } else {
          errors.value.email = "Wystąpił błąd";
          errors.value.password = "";
          errors.value.otp = "";
        }
      });
    });
};
</script>

<template>
  <form
    @submit.prevent="onSubmit"
    action="/api/login"
    method="post"
    class="app-auth-login-form"
  >
    <div class="app-auth-login-form__wrapper flex flex-col items-stretch">
      <div
        class="app-auth-login-form__fields flex flex-col items-stretch gap-8"
      >
        <div class="app-auth-login-form__field">
          <AppInput
            label="Email"
            type="email"
            v-model="formModel.email"
            autocomplete="email"
            :error="errors.email !== undefined"
            :hint="errors.email"
            absolute-hint
            required
          />
        </div>

        <div class="app-auth-login-form__field">
          <AppInput
            label="Hasło"
            type="password"
            v-model="formModel.password"
            autocomplete="current-password"
            :error="errors.password !== undefined"
            :hint="errors.password"
            absolute-hint
            required
            v-model:show-password="showPassword"
          />
        </div>
      </div>

      <div
        class="app-auth-login-form__recover flex flex-col items-end gap-4 mt-4"
      >
        <button
          type="button"
          class="underline hover:no-underline text-[0.625rem] leading-[0.625rem]"
          @click="openAuthDialog(AuthView.Recover)"
        >
          <span>Przypominanie hasła</span>
        </button>
      </div>

      <div
        v-if="requiresOtp"
        class="app-auth-login-form__fields flex flex-col items-stretch gap-8"
      >
        <div class="app-auth-login-form__field">
          <AppInput
            ref="otp"
            label="Jednorazowy kod"
            type="text"
            v-model="formModel.otp"
            autocomplete="one-time-code"
            :error="errors.otp !== undefined"
            :hint="errors.otp"
            absolute-hint
            required
          />
        </div>
      </div>

      <div
        class="app-auth-login-form__actions mt-12 flex flex-col items-stretch gap-6"
      >
        <AppBtn type="submit" variant="solid" block> Zaloguj się </AppBtn>

        <p
          class="text-center flex items-center justify-center flex-wrap gap-1 text-xs leading-[0.875rem]"
        >
          <span>Nie posiadasz konta?</span>
          <button
            class="font-bold underline hover:no-underline"
            @click="openAuthDialog(AuthView.Register)"
          >
            <span>Dołącz za darmo</span>
          </button>
        </p>
      </div>
    </div>
  </form>
</template>
