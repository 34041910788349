<script setup lang="ts">
const emit = defineEmits<{
  setTitle: [title: string];
  setSuccess: [value: boolean];
}>();

const title = ref("Nie pamiętasz hasła?");
watch(title, (title) => emit("setTitle", title), { immediate: true });

const success = ref(false);
watch(success, (success) => emit("setSuccess", success), { immediate: true });

watch(
  success,
  (success) => {
    if (success) title.value = "Link został wysłany";
    else title.value = "Nie pamiętasz hasła?";
  },
  { immediate: true }
);
</script>

<template>
  <div class="app-auth-recover h-full">
    <div
      class="app-auth-recover__wrapper h-full flex flex-col items-stretch gap-6"
    >
      <p v-if="success">Pamiętaj, że jest on ważny 24 godziny.</p>
      <p v-else>
        Poniżej podaj adres email użyty podczas rejestracji, a link restartu
        hasła zostanie wysłany.
      </p>

      <div class="app-auth-recover__form grow flex flex-col items-stretch">
        <AppAuthRecoverSuccess
          v-if="success"
          class="grow"
          @close="success = false"
        />
        <AppAuthRecoverForm v-else class="grow" @success="success = true" />
      </div>
    </div>
  </div>
</template>
