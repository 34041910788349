<script setup lang="ts">
const { loginWithGoogle, loginWithFacebook } = useAuth();

const emit = defineEmits<{
  setTitle: [title: string];
  setSuccess: [value: boolean];
}>();

const title = ref("Zaloguj się za pomocą:");
watch(title, (title) => emit("setTitle", title), { immediate: true });
</script>

<template>
  <div class="app-auth-login h-full pt-5">
    <div
      class="app-auth-login__wrapper h-full flex flex-col items-stretch gap-6"
    >
      <div class="app-auth-login__social flex flex-col items-stretch gap-6">
        <AppBtn block variant="outline" @click="loginWithGoogle">
          <LogoGoogle class="w-6 h-6 mr-2" />
          <span>Konta Google</span>
        </AppBtn>

        <AppBtn block variant="outline" @click="loginWithFacebook">
          <LogoFacebook class="w-6 h-6 mr-2" />
          <span>Konta Facebook</span>
        </AppBtn>
      </div>

      <div
        class="app-auth-login__prompt text-center grow flex flex-col items-stretch justify-center"
      >
        <p class="text-center">lub za pomocą email</p>
      </div>

      <div class="app-auth-login__form">
        <AppAuthLoginForm />
      </div>
    </div>
  </div>
</template>
