<script setup lang="ts">
const emit = defineEmits<{
  setTitle: [title: string];
  setSuccess: [value: boolean];
}>();

const title = ref("Potwierdzanie adresu email");
watch(title, (title) => emit("setTitle", title), { immediate: true });

const success = ref(false);
watch(success, (success) => emit("setSuccess", success), { immediate: true });

watch(
  success,
  (success) => {
    if (success) title.value = "Cześć!";
    else title.value = "Potwierdzanie adresu email";
  },
  { immediate: true }
);

const { confirmationToken, confirmEmail, isAuthDialogOpen } = useAuth();
const localePath = useLocalePath();

const { data } = await useAsyncData(
  `confirm-email:${confirmationToken.value}`,
  async () =>
    confirmationToken.value
      ? await confirmEmail(confirmationToken.value)
          .then(() => true)
          .catch(async () => {
            isAuthDialogOpen.value = false;
            await navigateTo(localePath({ name: "index" }));
            return false;
          })
      : false,
  { immediate: true, watch: [confirmationToken] }
);

watch(
  data,
  (data) => {
    if (data === true) success.value = true;
    else success.value = false;
  },
  { immediate: true }
);
</script>

<template>
  <div class="app-auth-reset h-full">
    <div
      class="app-auth-reset__wrapper h-full flex flex-col items-stretch gap-6"
    >
      <p v-if="success">Twoje konto jest już gotowe do użytkowania.</p>
      <p v-else>Ładowanie...</p>

      <div class="app-auth-reset__form grow flex flex-col items-stretch">
        <AppAuthConfirmSuccess
          v-if="success"
          class="grow"
          @close="success = false"
        />
      </div>
    </div>
  </div>
</template>
