<script setup lang="ts">
import { AuthView } from "#imports";

const emit = defineEmits<{ success: [] }>();

const { registerWithEmail, openAuthDialog } = useAuth();

const formModel = reactive({
  name: "",
  email: "",
  password: "",
  repeatPassword: "",
});

const showPassword = ref(false);

const resetModel = () => {
  formModel.name = "";
  formModel.email = "";
  formModel.password = "";
  formModel.repeatPassword = "";
};

const errors = ref<Record<string, string>>({});

const { isPasswordStrong } = useAuth();

const onSubmit = async () => {
  errors.value = {};

  if (formModel.password !== formModel.repeatPassword) {
    errors.value.repeatPassword = "Hasła nie są takie same";
    return;
  }

  if (!isPasswordStrong(formModel.password)) {
    errors.value.password =
      "Hasło musi zawierać minimum 8 znaków, małą literę, wielką literę, cyfrę i znak specjalny";
    return;
  }

  return await registerWithEmail(
    formModel.name,
    formModel.email,
    formModel.password
  )
    .then(() => {
      resetModel();
      emit("success");
    })
    .catch((response) => {
      const hasCode = (code: string) => {
        return !!response?.data?.errors?.find(
          (error: any) => error.extensions.code === code
        );
      };

      nextTick(() => {
        errors.value.name = "";
        errors.value.email = "";
        errors.value.password = "";
        errors.value.repeatPassword = "";
      });
    });
};
</script>

<template>
  <form
    @submit.prevent="onSubmit"
    action="/api/register"
    method="post"
    class="app-auth-register-form"
  >
    <div class="app-auth-register-form__wrapper flex flex-col items-stretch">
      <div
        class="app-auth-register-form__fields flex flex-col items-stretch gap-8"
      >
        <div class="app-auth-register-form__field">
          <AppInput
            label="Imię"
            type="text"
            v-model="formModel.name"
            autocomplete="given-name"
            :error="errors.name !== undefined"
            :hint="
              errors.name !== undefined
                ? errors.name
                : 'Wyświetlana nazwa użytkownika'
            "
            absolute-hint
            required
          />
        </div>

        <div class="app-auth-register-form__field">
          <AppInput
            label="Email"
            type="email"
            v-model="formModel.email"
            autocomplete="email"
            :error="errors.email !== undefined"
            :hint="
              errors.email !== undefined
                ? errors.email
                : 'Email używany do logowania'
            "
            absolute-hint
            required
          />
        </div>

        <div class="app-auth-register-form__field">
          <AppInput
            label="Hasło"
            type="password"
            v-model="formModel.password"
            autocomplete="new-password"
            :error="errors.password !== undefined"
            :hint="
              errors.password !== undefined
                ? errors.password
                : 'Wymagane minimum 8 znaków w tym jedna cyfra i znak specjalny'
            "
            absolute-hint
            required
            v-model:show-password="showPassword"
          />
        </div>

        <div class="app-auth-register-form__field">
          <AppInput
            label="Powtórz hasło"
            type="password"
            v-model="formModel.repeatPassword"
            autocomplete="new-password"
            :error="errors.repeatPassword !== undefined"
            :hint="errors.repeatPassword"
            absolute-hint
            required
            v-model:show-password="showPassword"
          />
        </div>
      </div>

      <div
        class="app-auth-register-form__actions mt-[1.125rem] flex flex-col items-stretch gap-6"
      >
        <AppBtn type="submit" variant="solid" block> Utwórz konto </AppBtn>

        <p
          class="text-center flex items-center justify-center flex-wrap gap-1 text-xs leading-[0.875rem]"
        >
          <span>Posiadasz konto?</span>
          <button
            class="font-bold underline hover:no-underline"
            @click="openAuthDialog(AuthView.Login)"
          >
            <span>Posiadasz konto?</span>
          </button>
        </p>
      </div>
    </div>
  </form>
</template>
